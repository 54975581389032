.container{
    padding: 50px 80px;
}
.aboutUs{
    display: flex;
    justify-content: space-evenly;
    video{
        border-radius: 50%;
        aspect-ratio: 1;
        height: 100%;
    }
    .contents{
        max-width: 500px;
        h2{
            display: flex;
            align-items: center;
            span{
                margin-left: 10px;
            }
            span:nth-child(3){
                color: #C50568;
                margin-left: 5px;
            }
        }
        p{
            line-height: 22px;
            color: rgb(112, 112, 112);
        }
    }
}
.ourTeams{
    h2{
        display: flex;
        align-items: center;
        span{
            margin-left: 10px;
        }
        span:nth-child(3){
            color: #C50568;
            margin-left: 5px;
        }
    }
    .members{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
        .member{
            width: 20%;
            position: relative;
            Button{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 55px;
                background-color: transparent;
                color: transparent;
                box-shadow: none;
                transition: all 1s;
            }
        }
        .member:hover{
            Button{
                background-color: rgba(0, 0, 0, 0.336);
                color: white;
            }
        }
        img{
            width: 100%;
        }
    }
}
.history{
    display: flex;
    justify-content: center;
}
.timeline {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    position: relative;
    margin-top: 50px;
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 8%; /* Adjusts the starting point of the line */
    width: 84%; /* Ensures the line only spans between the first and last event */
    height: 2px;
    background-color: #ccc;
}

.timeline-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 40px;
}

.timeline-event:before {
    content: '';
    position: absolute;
    top: -40px; /* Connects the top line */
    width: 2px;
    height: 40px;
    background-color: #ccc;
}

.event-icon img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 10px;
}

.event-date {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.event-description {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    max-width: 150px;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}
.custom-button {
    padding: 5px 10px !important;
  }

@media (max-width: 768px) {
    .container{
        padding: 50px 30px;
    }
    .aboutUs{
        flex-direction: column;
    }
    .members{
        .member{
            width: 45% !important;
        }
    }
    .timeline {
        width: 100%;
        position: relative;
        margin-top: 50px;
    }
    .timeline-event {
        width: 110px;
    }
    .timeline:before {
        left: 16%; 
        width: 68%;
    }
}
/** @format */

.background-container-pvcpipes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-image: url("../../Accets/pvcpipes\ background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pvcpipes-text {
  text-align: center;
  color: white;
  margin: 60px 0;
}
.marquee-container {
  padding-left: 100px;
}
.pvcpipes-textone {
  margin-bottom: 10px;
  display: block !important;
  color: #fff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pvcpipe-texttwo {
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 10px black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 33.6px */
}

.pvcpipes-scroll {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.pvcpipes-scroll {
  margin: 2rem 6rem;
}
.pvcpipes-marquee-logo {
  width: 100px;
  height: 100px;
}
.pvcpipes-marquee-logo {
  margin-right: 20px;
}

.pvcpipes-marquee-logo.last-logo {
  margin-right: 50px;
}
/* table */
/* WiresTable.css */
.wires-container {
  padding: 20px 20px 0px;
  font-family: Arial, sans-serif;
  margin: 4rem 6rem 0;
}

.wires-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  gap: 5px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #1e2d4d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.highlighted {
  color: #ea267c;
}

.wires-table {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.table-section {
  flex: 1;
  min-width: 250px;
  border-radius: 5px;
}

.section-title {
  background-color: #1e2d4d;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 18px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

thead th {
  text-align: left;
  padding: 10px;
}

tbody td {
  padding: 10px;
  border-top: 1px solid #ddd;
}

tbody tr:hover {
  background-color: #f5f5f5;
}
.wires-logo-image {
  width: 34px;
  height: 47px;
  position: relative;
  left: -10px;
  top: 5px;
}
/* cipping */
.casing-capping-container {
  margin: 0 6rem;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.casing-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  gap: 5px;
  font-weight: bold;
  color: #000;
}

.casing-title .icon {
  font-size: 24px;
  margin-right: 10px;
}

.casing-title .details {
  color: #db007d;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.details-table th,
.details-table td {
  padding: 10px;
  text-align: left;
  /* border: 1px solid #e0e0e0; */
}

.details-table th {
  background-color: #f9fafb;
  /* font-weight: bold; */
  color: #667085;
  text-align: center;
}
.detail-main-td {
  text-align: center !important;
}
.details-sub-td {
  text-align: end !important;
}
.details-table td {
  color: #333;
  color: #101828;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.note {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: #000;
}
.casing-imagelogo {
  width: 34px;
  height: 47px;
  position: relative;
  left: -5px;
}
.details-text-casing {
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.wires-span-text{
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (max-width: 768px) {
  .background-container-pvcpipes {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    background-image: url("../../Accets/pvcpipes\ background.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .pvcpipes-textone {
    position: relative;
    left: 0px;
    font-size: 60px;
    margin: 10px;
    text-align: center;
  }
  .pvcpipe-texttwo {
    font-size: 14px;
  }
  .pvcpipes-scroll {
    margin: 2rem 1.3rem;
  }
  .wires-table {
    flex-direction: column;
  }
  .wires-container {
    margin: 1rem 0rem;
  }
  .marquee-container {
    padding-left: 0px;
  }
  .casing-capping-container {
    margin: 1rem 0;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  .casing-title{
    font-size: 20px;
  }
  .casing-imagelogo {
    width: 26px;
    height: 41px;
    position: relative;
    left: -5px;
    top: 5px;
}
.details-table td {
  font-size: 13px;
}
tbody td {
  font-size: 13px;
}
}

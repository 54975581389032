.slider{
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.list {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  width: max-content;
  transition: 1s;
}
.list img {
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  /* object-fit: cover; */
  /* object-position: top left; */
}
.buttons{
  position: absolute;
  top: 45%;
  left: 5%;
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.buttons button {
  width: 50px;
  height: 50px;
  border-radius: 505;
  background-color: #fff5;
  color: #fff;
  border: none;
  font-family: monospace;
  font-weight: bold;
}
.dots{
  position: absolute;
  bottom: 10px;
  color: #fff;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  transition: 1s;
}
.dots li {
  list-style: none;
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin: 20px;
  border-radius: 20px;
}
.dots li.active {
  width: 30px;
}
@media screen and ( max-width: 768px) { 
  .slider {
      height: 270px;
  }
  
}
/** @format */

.container {
  padding: 50px 80px;
}

.Our-Products-mainbody {}

.Our-Products-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 130px;
}

.Our-Products-image {
  width: 33px;
  /* height: 47px; */
}

.product-image-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.product-main-title {
  color: #000;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-title-span {
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-viewll {
  text-decoration: none;
  color: #c50568;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  padding: 10px;
  margin: 5px 130px;

  a {
    text-decoration: none;
    color: black;
  }
}

.card {
  background-color: #fff;
  overflow: hidden;
  transition: transform 0.3s ease;
  text-align: center;
  margin: 10px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}

/* .card:hover {
  transform: translateY(-5px);
} */

.card-image {
  width: 100%;
  height: auto;
}

.card-text {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}

.certificate-view-all {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #c50568;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.expertise-main-body {
  width: 100%;
  height: 320px;
  background-color: #1e2d4d;
  margin-bottom: 0.5%;
  margin-top: 30px;
}

.expertise-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  margin: 10px;
  /* padding-top: 10px; */
}

.expertise-title {
  color: #c50568;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.expertise-logos {
  width: 55px;
  height: 55px;
  position: relative;
  top: -20px;
}

.expertise-last-title {
  color: #fff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.expertise-last-title-two {
  color: #fff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  top: 10px;
}

.expertise-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.expertise-title-last {
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 60px;
  font-weight: 600;
}

/* units css */
.ourunits-main-body {
  margin-bottom: 2%;
}

.ourunits-image {
  width: 34px;
  position: relative;
  top: 30px;
}

.ourunits-image-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin: 5px 130px;
}

.ourunits-inside-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 130px;
}

.ourunits-location {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.vertical-line {
  width: 2px;
  height: 70px;
  background: rgba(0, 0, 0, 0.2);
}

.ourunits-title {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bounce-icon {
  display: inline-block;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

/* certified css */
.certified-image-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 120px;
}

.certified-image {
  width: 34px;
}

.certified-imagespan {
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.certified-imagetext {
  color: #000;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.certified-image-mainbody {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 4%;
}

.certified-table {
  margin-bottom: 3%;
  margin-top: 3%;
  width: 900px;
  height: 275px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.certified-table-main {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-side,
.right-side {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.vertical-line-two {
  width: 1px;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.2);
}

.certified-table-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: column;
}

.logo-arrow {
  width: 15px;
  height: 15px;
}

.certified-table-row li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

/* marquee */
.marquee img {
  /* margin-right: -20px;  */
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.marquee-logo {
  width: 100px;
  height: 100px;
}

.marquee-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 130px 0 !important;
}

.marquee {
  display: flex;
  block-size: var(--marquee-item-height);
  margin-block: var(--marquee-item-height);
  position: relative;
  overflow-x: hidden;
  mask-image: linear-gradient(to right,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0));
}

.marquee--8 {
  --marquee-item-width: 100px;
  --marquee-item-height: 20px;
  --marquee-duration: 6s;
  --marquee-items: 8;
}

.marquee__item {
  --marquee-item-offset: max(calc(var(--marquee-item-width) * var(--marquee-items)),
      calc(100% + var(--marquee-item-width)));
  --marquee-delay: calc(var(--marquee-duration) / var(--marquee-items) * (var(--marquee-items) - var(--marquee-item-index)) * -1);
  position: absolute;
  inset-inline-start: var(--marquee-item-offset);
  transform: translateX(-50%);
  animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;
}

.marquee__item:nth-child(4n) {
  border-top-right-radius: 1rem;
}

.marquee__item:nth-child(4n + 1) {
  border-bottom-right-radius: 1rem;
}

.marquee__item:nth-child(4n + 2) {
  border-bottom-left-radius: 1rem;
}

.marquee__item:nth-child(4n + 3) {
  border-top-left-radius: 1rem;
}

.marquee--8 .marquee__item:nth-of-type(1) {
  --marquee-item-index: 1;
}

.marquee--8 .marquee__item:nth-of-type(2) {
  --marquee-item-index: 2;
}

.marquee--8 .marquee__item:nth-of-type(3) {
  --marquee-item-index: 3;
}

.marquee--8 .marquee__item:nth-of-type(4) {
  --marquee-item-index: 4;
}

.marquee--8 .marquee__item:nth-of-type(5) {
  --marquee-item-index: 5;
}

.marquee--8 .marquee__item:nth-of-type(6) {
  --marquee-item-index: 6;
}

.marquee--8 .marquee__item:nth-of-type(7) {
  --marquee-item-index: 7;
}

.marquee--8 .marquee__item:nth-of-type(8) {
  --marquee-item-index: 8;
}

.marquee--3 .marquee__item:nth-of-type(1) {
  --marquee-item-index: 1;
}

.marquee--3 .marquee__item:nth-of-type(2) {
  --marquee-item-index: 2;
}

.marquee--3 .marquee__item:nth-of-type(3) {
  --marquee-item-index: 3;
}

.marquee--6 .marquee__item:nth-of-type(1) {
  --marquee-item-index: 1;
}

.marquee--6 .marquee__item:nth-of-type(2) {
  --marquee-item-index: 2;
}

.marquee--6 .marquee__item:nth-of-type(3) {
  --marquee-item-index: 3;
}

.marquee--6 .marquee__item:nth-of-type(4) {
  --marquee-item-index: 4;
}

.marquee--6 .marquee__item:nth-of-type(5) {
  --marquee-item-index: 5;
}

.marquee--6 .marquee__item:nth-of-type(6) {
  --marquee-item-index: 6;
}

@keyframes go {
  to {
    inset-inline-start: calc(var(--marquee-item-width) * -1);
  }
}

.marquee-text {
  flex: 1;
  padding-right: 20px;
}

.marquee {
  flex: 3;
  display: flex;
  flex-direction: row;
  height: 250px;
  overflow: hidden;
}

.marquee-logo {
  width: 100px;
  margin: 10px 0;
}

.worked-title {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center !important;
}
.worked-title-mobile{
  display: none;
}

@media screen and (max-width: 768px) {
  .product-main-title {
    font-size: 20px;
    white-space: nowrap;
  }

  .product-title-span {
    font-size: 20px;
  }

  .Our-Products-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 13px;
  }

  .Our-Products-image {
    width: 22px;
    /* height: 25px; */
  }

  /* expertise */
  .expertise-body {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    margin: 10px;
    flex-direction: column;
    padding-bottom: 40px;
  }

  .expertise-main-body {
    width: 100%;
    height: max-content;
    background-color: #1e2d4d;
    margin-bottom: 0.5%;
    margin-top: 30px;
  }

  .expertise-logos {
    width: 55px;
    height: 55px;
    position: relative;
    top: -20px;
  }

  .expertise-last-title {
    font-size: 14px;
  }

  .expertise-last-title-two {
    font-size: 14px;
    position: relative;
    top: 10px;
  }

  .expertise-title {
    font-size: 40px;
  }

  .expertise-title-last {
    font-size: 40px;
  }

  .expertise-logo-icon {
    font-size: 30px !important;
    position: relative !important;
    top: 0px !important;
  }

  /* units */
  .vertical-line {
    display: none;
  }

  .ourunits-image {
    width: 22px;
    top: 20px;
    position: relative;
  }

  .ourunits-inside-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 30px;
    flex-direction: column;
  }

  .ourunits-image-section {
    margin: 5px 20px;
  }

  /* certified */
  .certified-image {
    width: 22px;
  }

  .certified-imagespan {
    font-size: 17px;
  }

  .certified-imagetext {
    font-size: 20px;
    text-align: center !important;
    white-space: nowrap;
  }

  .certified-image-mainbody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 0px;
  }

  .certified-image-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 5px 20px;
    gap: 10px;

    .viewallbtn2 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .certified-table {
    width: 90%;
    height: max-content;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: column;
    padding-top: 20px;
  }

  .left-side,
  .right-side {
    width: 100%;
    ul{
      margin: 4px !important;
    }
  }
  .vertical-line-two {
    display: none;
  }

  .certified-table-row li {
    font-size: 12px;
  }

  .worked-title {
    font-size: 16px;
    display: none;
  }
  .worked-title-mobile{
    display: block;
    font-size: 20px;
    margin-top: 40px;
    text-align: center;
  }

  .marquee {
    flex: 3;
    display: flex;
    flex-direction: row;
    height: 150px;
    overflow: hidden;
  }

  .marquee-container {
    margin-top: 0px;
    padding: 0 20px 0 !important;
    /* flex-direction: column; */
  }
  .bbb .marquee--8 {
    --marquee-item-height: 0px;
  }

  .marquee img {
    /* margin-right: -20px;  */
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 80px;
    height: 80px;
  }

  .card-grid {
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards per row on mobile */
    margin: 0px 0px;
  }

  .certificate-view-all {
    text-decoration: none;
    font-size: 11px;
    display: flex;
    align-items: center;
    margin-top: 00px;
  }
}
/** @format */

.cables-background {
  background-image: url("../../Accets/cable\ background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 568px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.cables-background-text > h1 {
  margin: 10px 0px;
  display: block !important;
  color: #fff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cables-background-text > h3 {
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 10px black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.product-heading {
  font-size: 24px;
  margin-bottom: 20px;
  margin-left: 20px;
  text-align: left;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-items {
  list-style-type: none;
  padding-left: 0;
  margin: 4%;
}
.product-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.product-item span {
  color: #d10062;
  margin-right: 10px;
  font-weight: bold;
}
.product-list {
  padding: 20px;
  max-width: 1200px;
  margin: 3rem 6rem;
}
.cables-cuto-image-product {
  width: 34px;
  height: 47px;
  position: relative;
  top: 18px;
  right: 10px;
}
.cables-cuto-image {
  width: 34px;
  height: 47px;
  position: relative;
  top: 18px;
  margin: 0px 7px;
}
.cables-textslide {
  margin: 3rem 7rem;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cables-custo-span {
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cables-spantext {
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* cable slider */
.cablepage-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.left-side-text {
  flex-shrink: 0;
  width: 100px;
  padding-right: 20px;
}

.cablepage-marquee {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  display: flex;
  gap: 10px;
  animation: marquee 10s linear infinite;
  align-items: center;
  padding-left: 20px;
}

.marquee-item {
  min-width: 150px;
}

.marquee-item img {
  display: block;
  width: 100px;
  height: 100px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.product-cable-text {
  margin: 0px 20px;
  padding-bottom: 20px;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.arrow-cable-li {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 768px) {
  .cables-background {
    background-image: url("../../Accets/cable\ background.png");
    width: 100%;
    height: max-content;
    display: flex;
  }
  .cables-background-text > h3 {
    font-size: 14px;
  }
  .cables-background-text{
    margin: 50px 0;
  }
  .product-list {
    padding: 10px;
  }

  .product-heading {
    font-size: 20px;
  }

  .product-item {
    font-size: 14px;
    text-align: justify;
  }
  .product-list {
    margin: 1rem 0rem;
  }
  .cablepage-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-side-text {
    margin-bottom: 20px;
  }
  .cablepage-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-side-text {
    margin-bottom: 20px;
  }
  .cables-textslide {
    margin: 1rem 1rem;
    font-size: 20px;
  }
  .cables-custo-span {
    font-size: 20px;
  }
  .cables-spantext {
    font-size: 20px;
  }
  /* .cables-cuto-image{
    width: 20px;
    height: 20px;
  } */
}

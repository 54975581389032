.css-19pu4hj{
    button{
        margin: 40px 20px;
        text-transform: none;
    }
}
.logo{
    width: 130px;
}
.menuitem{
    margin: 40px;
}

@media (max-width: 860px) {
    .logo{
        width: 80px;
        margin: 10px 0px;
    }
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
        margin: 0 !important;
    }
    .menuitem{
        margin: 0px;
    }
    .css-11b4hzg-MuiToolbar-root{
        justify-content: space-between !important;
    }
    .css-2uchni{
        display: none !important;
    }
}
/** @format */

.background-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-image: url("../../Accets/led\ background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-container {
  text-align: center !important;
  color: white;
  margin: 60px 0;
}

.text-primary {
  margin-bottom: 10px;
  display: block !important;
  color: #fff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-secondary {
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 10px black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 33.6px */
}

.led-customer-scroll {
  margin: 5rem 6rem;
}

.led-logo {
  width: 34px;
  height: 47px;
  position: relative;
  top: 15px;
}
.led-logo-text-span {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.led-logo-span {
  color: #c50568;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
/* Marquee.css */
.marquee-container-led {
  margin-left: 90px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}

.marquee-ledpage {
  display: flex;
  gap: 20px;
  animation: scroll 15s linear infinite;
  width: max-content;
}

.marquee-ledpage > img {
  width: 100px;
  height: 100px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* product table */

.products-container {
  margin: 60px 110px;
  margin-bottom: 30px;
}

h1 {
  display: flex;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.icon {
  font-size: 30px;
  margin-right: 10px;
}

.highlight {
  color: #e91e63;
}

.products-table {
  width: 700px;
  border-collapse: collapse;
  margin-top: 50px;
}

.products-table th,
.products-table td {
  padding: 12px 15px;
  text-align: left;
}
.products-table tr {
  border-bottom: 1px solid #ddd; /* Only add bottom border to rows */
}
.products-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  min-width: 150px;
}

.products-table td {
  font-size: 16px;
  border: none;
}

.bullet {
  color: #e91e63;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .products-table th,
  .products-table td {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .text-primary {
    font-size: 2rem;
  }

  .text-secondary {
    font-size: 1.5rem;
  }
  .background-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    background-image: url("../../Accets/led\ background.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .text-primary {
    margin-bottom: 10px;
    color: #fff;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 60px;
    margin: 0px 0px 10px;
  }

  .text-secondary {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
  .led-logo-text-span {
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .led-logo-span {
    color: #c50568;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .led-customer-scroll {
    margin: 2rem 2rem;
  }
  .led-logo {
    width: 20px;
    height: 25px;
    object-fit: cover;
  }
  .marquee-ledpage > img {
    width: 80px; 
    height: 80px;
  }

  .marquee-ledpage {
    gap: 10px;
  }
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .marquee-container-led {
    margin-left: 0px;
    margin-bottom: 20px;
    position: relative;
  }
  .products-table {
    width: 100% !important;
    border-collapse: collapse;
    margin-top: 50px;
  }
  .products-container {
    margin: 10px 30px;
    margin-bottom: 30px;
  }
}

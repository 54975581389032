.container{
    padding: 50px 80px;
}
.ourCertifications{
    h2{
        display: flex;
        align-items: center;
        span{
            margin-left: 10px;
        }
        span:nth-child(3){
            color: #C50568;
        }
    }
    .certificates{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;

        .cert-box{
            width: 22%;
            padding: 10px;
            margin: 10px 0;
            border-radius: 10px;
            transition: all 0.3s;
            h4{
                margin-top: 10px;
                margin-bottom: 5px;
            }
            p{
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }
        .cert-box:hover{
            background-color: rgb(0, 11, 75);
            color: white;
        }
        img{
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .container{
        padding: 50px 30px;
    }
    .ourCertifications{
        flex-direction: column;
    }
    .certificates{
        .cert-box{
            width: 41% !important;
        }
    }
}
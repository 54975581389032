/** @format */
a{
  text-decoration: none;
}
.footer-logo-location {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-top: 15px;
}
.footer-main-logo {
  width: 210px;
  height: 100px;
  object-fit: cover;
  margin-left: 40px;
  margin-bottom: 20px;
  margin-top: 25px;
}
.footer-title {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 15px;
}
.footer-connect-withus {
  color: rgba(255, 255, 255, 0.7);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  left: 9px;
}
.footer-connect-maintitle {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-style: normal;
  margin-top: 25px;
  font-weight: 600;
  line-height: normal;
}
.foot-connect-logo {
  position: relative;
  left: -6px;
  top: 4px;
}
.foot-our-network {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 30px;
}
.foot-network-menu {
  color: rgba(255, 255, 255, 0.7);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.foot-netwoek-main {
  position: relative;
  top: 20px;
}
.foot-getintouch-title {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 5px;
}
.foot-contactus-button {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.foot-contactus-today {
  color: rgba(255, 255, 255, 0.7);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.foot-logo-all {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.foot-logos {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  object-fit: cover;
}
.foot-getintouch-main {
  position: relative;
  top: 20px;
}
.footer-main-body {
  width: 100%;
  height: 280px;
  background: #1e2d4d;
  padding-top: 20px;
}
.foot-main-body-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}
.footer-mobile-side{
  display: flex;
  justify-content: space-evenly;
  gap: 60px;
}
.footer-links{
  color: white;
}
.ccc{
  margin-top: 5px;
}
@media (max-width: 768px) {
  .footer-main-logo {
    margin-top: 30px;
    margin-left: 0px;
    height: 50px;
    width: 100px;
  }
  .footer-main-body {
    width: 100% !important;
    height: fit-content !important;
    padding-bottom: 60px;
    background: #1e2d4d;
    padding-top: 20px;
  }
  .footer-mobile-side{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 10px;
    width: 45%;
  }
  .footer-title {
    font-size: 11px;
  }
  .footer-connect-maintitle, .foot-our-network , .foot-getintouch-title{
    font-size: 16px;
    margin-top: 10px;
  }
  .footer-connect-maintitle{
    margin-top: 18px;
  }
  .foot-network-menu{
    font-size: 11px;
    margin: 7px 0px;
  }
  .foot-connect-logo{
    width: 15px;
    font-size: 11px;
  }
  .footer-connect-withus{
    margin: 7px 0px;
    font-size: 11px;
  }
  .foot-contactus-today{
    margin-top: 0;
    font-size: 11px;
  }
  .foot-contactus-button {
    height: 35px !important;
    width: 100% !important;
  }
  .foot-logos{
    width: 25px;
    height: 25px;
  }
  .footer-logo-location{
    width: 16px;
  }
  /* .foot-main-body-header {
    justify-content: center !important;
    flex-direction: column !important;
  } */
  .foot-getintouch-main{
    display: flex;
    align-items: self-start;
    justify-content: center;
    flex-direction: column !important;
  }
  .foot-netwoek-main {
    width: fit-content;
  }
}

@media (max-width: 468px) {
  .footer-main-body {
    width: 100% !important;
    height: fit-content !important;
    background: #1e2d4d;
    padding-top: 0px;
  }
  /* .foot-main-body-header {
    justify-content: center;
    flex-direction: column !important;
  } */
}
